import { DatePipe } from "@angular/common";
import { OnInit, AfterViewChecked, AfterViewInit, Component, ChangeDetectorRef, Input, ViewChild } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NbDialogRef, NbToastrService } from "@nebular/theme";
import { LocalDataSource, Ng2SmartTableComponent } from "ng2-smart-table";
import { BaseComponent } from "../../../pages/client/base/base-component";
import { Address } from "../../models/address.model";
import { DosimeterMake } from "../../models/dosimeterMake.model";
import { DosimeterMeasurement } from "../../models/dosimeterMeasurement.model";
import { DosimeterPosition } from "../../models/dosimeterPosition.model";
import { MonitoringFrequency } from "../../models/monitoringFrequency.model";
import { PersonModel } from "../../models/person.model";
import { Profession } from "../../models/profession.model";
import { AuthService } from "../../services/auth/auth.service";
import { DataService } from "../../services/data/data.service";
import { LanguageService } from "../../services/language/language.service";
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { MonitoringType } from "../../models/monitoringType.model";

@Component({
    selector: 'ngx-addbulkmonitoring',
    templateUrl: './addbulkmonitoring.component.html',
    styleUrls: ['./addbulkmonitoring.component.scss']
  })
  export class AddBulkMonitoring extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked { 
    @Input() profileId: any;
    @Input() monitoringTypes: any[];
    @Input() dosimeterMake: any[];
    @Input() organizationId: any[];
    @Input() dosimeterType: any[];
    @Input() dosimeterPosition: any[];
    @Input() suborganizations: any[];
    @Input() monitoringType: any[];
    @Input() monitoringFequency: any[];
    @Input() lang: any;
    @Input() selectedSuborganizationId: any;
    @ViewChild(Ng2SmartTableComponent) smartTable: Ng2SmartTableComponent;

    profile: any = {};
    firstForm: FormGroup;
    secondForm: FormGroup;
    monitoring_types = [];
    dosimeter_make = [];
    dosimeter_type = [];
    dosimeter_position = [];
    monitoring_frequency =[];
    organizations = [];
    editEnabled = false;
    doEmit: boolean;
    public loadingData:Boolean=false;
    

    private selectedRows:any[];
    currentOrganization: any;
    checked=true;
    selectedOrganizationExposed: any;
    exposedSource: LocalDataSource = new LocalDataSource();
    
    
    exposedSettings = {
      mode:'external',
      selectMode: 'multi',
      pager : {
        display: true,
        perPage: 10
      },
      actions:{
        columnTitle: '',
        add: false,
        delete: false,
        edit: false,
        select: true
      },
      columns: { }
    };

  
  exposedDefaultColumns = {
    firstName: {
      title: this.ls.strings.first_name,
      type: "string",
    },
    lastName: {
      title: this.ls.strings.last_name,
      type: "string",
    },
    code: {
      title: this.ls.strings.wearer_code,
      type: "string",
    },
    
    monitoring_type: {
      title: this.ls.strings.menu_monitoring_types,
      valuePrepareFunction: (key) => {
        let t=key;
        let s=[];
        try {
        for (var k in t) {
          let v=t[k].monitoring_type;
          // console.log(k, " ------ vs ------",  v, this.monitoringTypes)
          let _key=this.monitoringTypes.filter((obj)=> { return obj.service.value==v;})[0].key;
          s.push(_key);
        }
        
      } catch(e) {
        
      } 
        return s.toString();
      },
      type: "string"
    }
  };
    constructor(public ls: LanguageService, public data: DataService, public auth: AuthService,
        private datePipe:DatePipe, private utilsService:UtilsService,
        public toastrService: NbToastrService, protected changeDetector:ChangeDetectorRef,
        protected ref: NbDialogRef<AddBulkMonitoring>) {
        super(data, auth, toastrService, ls, changeDetector); 

    }
    

    ngOnInit(): void {
      this.profile=new PersonModel();
      this.profile.active=true;
      this.profile.organizations = []
      this.profile.monitoring_type = null;
      this.profile.dosimeter_make = null;
      this.profile.dosimeter_type = null;
      this.profile.profession = null;
      this.profile.nationality = "";
      this.profile.position = null;
      this.profile.monitoring_frequency = null;
      this.profile.type="";
      this.profile.address = new Address();
      this.initialize();
      console.log("this profile mt is ", this.profile.monitoring_type, "ORG ID is ", this.organizationId, this.selectedSuborganizationId)

    }
    ngAfterViewChecked(): void {
      this.showSelectedRows();
    }
    ngAfterViewInit(): void {
      setTimeout(()=>{ 
        console.log(this.monitoringFequency, this.dosimeterPosition, "DOSI MAKE IS ",this.dosimeterMake)

       }, 500)  
    }


    async initialize() {
      await this.getCurrentOrganizationDetails();
      await this.getSuborganizations();
      await this.getMonitoringTypes();
      await this.getDosimeterMakes();
      await this.getPosition();
      await this.getMonitoringFrequency();
      
    };

 
    protected reinit(ob: any) {
      this.lang=this.ls.lang;
          this.ls.mySubject.subscribe(message => {
            setTimeout(() => {
              this.exposedSettings = {
                mode:'external',
                selectMode: 'multi',
                pager : {
                  display: true,
                  perPage: 10
                },
                actions:{
                  columnTitle:'',
                  add: false,
                  delete: false,
                  edit: false,
                  select: true
                },
                columns: this.exposedDefaultColumns
              };
  
          
            }, 100);
            
        });
        this.getMonitoringTypes();
    
    }
    
    async getDosimeterMakes() {
      let resp = await this.data.getGeneric(this.auth.user.token, new DosimeterMake());
      console.log("dosimeter makes", resp.docs)
      this.dosimeterMake = resp.docs;
    }

    async getSuborganizations() {
      console.log("get sub orgs yyy", this.auth.user.preffered_organization, this.currentOrganization)
      let resp = await this.data.getUserSuborganizations(this.auth.user.preffered_organization, this.auth.user.token, true);
      console.log("get sub orgs resp is ", resp)
      if (resp.status==200 || resp.length>0) {
      this.suborganizations = resp;
      } else {
  
        this.suborganizations=[];
      }
    }

    async getCurrentOrganizationDetails() {
      // this.currentOrganization = {};
      let t:any=this.auth.user.preffered_organization;
      if (t!=undefined && t.children) {
        this.auth.user.preffered_organization=t._id;
      }
      console.log("this auth.user.preffered_org",this.auth.user.preffered_organization)
      let current = await this.data.getOrganizationDetails(this.auth.user.preffered_organization, this.auth.user.token);
      console.log('current', current)
      this.currentOrganization = current.docs[0];
      if (this.currentOrganization.parent!="") { 
        this.auth.user.preffered_organization=this.currentOrganization.parent;
        await this.getCurrentOrganizationDetails();
      }
       //this.suborganizations=this.currentOrganization.children;
    }

    

    async getMonitoringTypes() {
      // let resp = await this.data.getGeneric(this.auth.user.token, new DosimeterMeasurement());
      // this.monitoringTypes=resp.docs
      this.monitoringTypes = this.currentOrganization.services; 
       console.log('monitoring types', this.monitoringTypes)
       
     }

     async getMonitoringFrequency() {
      let resp = await this.data.getGeneric(this.auth.user.token, new MonitoringFrequency());
      this.monitoringFequency = resp.docs;
      console.log("monitoring frequency", resp.docs)
    }
   
  
    async getPosition () {
      let resp = await this.data.getGeneric(this.auth.user.token, new DosimeterPosition())
      this.dosimeterPosition = resp.docs;
      console.log('dosimeterPosition', this.dosimeterPosition)
    }

    filterMonitoringType(mt) {

    }

      disabledButton(): Boolean {
    let bool = true;
    try {
      //console.log(this.profile)
    if(this.profile.suborganization!=null 
      && this.profile.dosimeter_make!=null 
      && this.profile.monitoring_type!=null 
      && this.profile.dosimeter_type!=null 
      && this.profile.dom!=null
      && this.profile.monitoring_frequency!=null) bool=false;
    } catch(e) {}
    return bool;
  }

   async updatePersons() {
    if (this.loadingData) { return }
    this.loadingData=true;
    let objects=[];
    objects=[...this.selectedRows];
    console.log("Objects are ", objects)
    let per = new PersonModel();
    let nm = new MonitoringType();
    for (var k in nm) {
      nm[k]=this.profile[k];
    }
    nm.allocEnd=nm.dom;
    for (var i=0;i<objects.length;i++) {
      objects[i].monitoring_type.push(nm);
      objects[i].collection_name=per.collection_name;
      //console.log("prepared for update ", objects[i])
    }
    console.log(this.profile, "new monitoring type is ", nm)
   let r=await this.data.updateManyGeneric(this.auth.user.token, objects);
    console.log("R is ", r);
    if (r.status == 200)  {
      console.log("ok");
      this.showToast("success", this.ls.strings.success,'');
     
    }
    else {
      this.showToast("warning", this.ls.strings.warning,''); 
    } 
    this.loadingData=false;
    this.ref.close(this.profile)
    }

    dismiss() {
      this.ref.close();
    }

    stepChangeHandler(ev) {
      console.log("step changed ??", ev, this.profile);

      this.getExposed(this.profile.suborganizationId, this.exposedDefaultColumns);
  
    }
    
    public onUserRowSelect(event) {
      if (this.selectedRows == null || this.selectedRows == undefined)
        this.selectedRows = [];

      switch(event.isSelected){
        case true:{
          this.selectedRows.push(event.data);
          break;
        }
        case false:{
          this.selectedRows.splice(this.selectedRows.indexOf(event.data), 1);
          break;
        }
        case null:{
          if (event.selected.length == 0)
          {
            this.selectedRows = [];
          }
          else
          {
            this.selectedRows = event.source.filteredAndSorted;
          }
          break;
        }
        default:
          break;
      }
      
      
      console.log("exposed source: ", this.exposedSource);


      console.log( "EVENT is ", event)
      console.log("ROW SELECTED IS ", this.selectedRows);
    };

    public showSelectedRows(){

      if (this.selectedRows == null || this.selectedRows == undefined)
        return;

      var count = 0;

      this.smartTable.grid.getRows().forEach((row) => {
        if (this.selectedRows.find(elem => elem._id === row.getData()._id)){
          row.isSelected = true;
          count++;
        }
      });

      // if (count === this.selectedRows.length)
      // {
      //   this.smartTable.grid.
      // }

      this.changeDetector.detectChanges();
    }

   

    addButtonDisabled(): Boolean {
      let bool=true;
      try{
      if (this.selectedRows!=null && this.selectedRows.length>0) bool=false;
      } catch(e) {}
      return bool
    }


    
    async getExposed(selectedOrganization: any, columns: any){ 
      console.log("---------------------", this.checked)
      let resp = await this.data.getOrganizationPersonsAdmin(selectedOrganization,this.auth.user.token, this.checked)
      if (resp==undefined) return;
      this.exposedSettings = Object.assign({},  {
        mode:'external',
        selectMode: 'multi',
        pager : {
          display: true,
          perPage: 10
        },
        rowClassFunction: (row) => {
          //console.log("row.data.userID:: " + row.data.disabled);
          if (row.data.active==!true || row.data.active== undefined) {
            return 'disabled_line';
          } 
          return '';
        },
        actions:{
          columnTitle:'',
          add:false,
          delete: false,
          edit: false,
          select: true
          
        },
   
        columns: columns 
      });
   
      if(resp.status==200){
        let arr=[];
        for (var k in resp.persons) {
            let p=resp.persons[k]
            if (this.utilsService.checkDuplicateMonitoring(p, this.profile)==false) {
              arr.push(p)
            }
        }
        this.exposedSource.load(arr);
      }

    }
  }