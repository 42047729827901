import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import { Router } from '@angular/router';
//import * as xml2js from 'xml2js'
import { ENV } from './alfresco.conf';
import { DataService } from '../data/data.service';

@Injectable({
  providedIn: 'root'
})
export class AlfrescoService {
  public _adminTicket:any = '';
  private heads: any; //HttpHeaders;
  public alf_url:any;
  private adm_pass;
  public nomenclatures:any;
  public uiConfig:any;
  public applicationWidgets: any;
  private dataService:DataService;
  constructor(private http: HttpClient, private router: Router) { 
    this.heads={
      'Access-Control-Allow-Origin': "*",
      "Access-Control-Allow-Headers": "Origin, X-Requested-With,  Accept"
    }
   
  //   this.loginAdmin();
     //this.initialize();
  }
  public setDataService(data) {
    this.dataService=data;
  }
  public setConfig(conf) {
    this.alf_url=conf.alf_url;
    this.adm_pass=conf.adm_pass;
    ENV.alfrescoUrl=conf.alf_url;
    ENV.ADM_PASS=conf.adm_pass;
   // console.log(this.alf_url, ENV.alfrescoUrl, "   ", ENV.ADM_PASS);
    this.loginAdmin()
  }
  private async initialize() {
    this.nomenclatures={};
    this.uiConfig={};
    let resp=await this.getNomenclatures();
    let confresp=await this.getUIConfig();
    console.log("aaa",confresp)
    let confkids:any=JSON.parse(confresp.filecontent);
    console.log("CONFIG ", confkids, confresp)
    for (var j=0;j<confkids.length;j++) {
      //console.log("=---------------------",j, confkids[j])
      this.uiConfig[confkids[j].name]=confkids[j].value;
    }
    let kids:any=resp.nomenclatures;
    for (var i=0;i<kids.length;i++) {
      this.nomenclatures[kids[i].name+"_list"]=kids[i].value;
    }
    //this.nomenclatures=await this.getNomenclatures();
  }
  public async loginAdmin() {
    
    let resp = await this._getAdminTicket();
    this._adminTicket = resp;
    
  }   

  public async getAdvancedSearch(q:any, token: string):Promise<any> {

    let rsp = await this.http.get(this.alf_url+ENV.API_GET_ADVANCED_SEARCH+"?alf_ticket="+token+q)
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)) as any)
    .catch(this._handleError);
    return rsp;
  } 
  public async getDosiTemplates(token):Promise<any> {

    let rsp = await this.http.get(ENV.API_GET_DOSI_TEMPLATES, 

      {
        headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', '*')
    .set('authorization', token)
      }

    )
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)) as any)
    .catch(this._handleError);
    return rsp;
  } 
  private async  _getAdminTicket(): Promise<any> {
    //let rsp= await this.http.get(this.alf_url+ENV.API_LOGIN + "?u=" + ENV.ADM_USR + "&pw=" + ENV.ADM_PASS, {
     // headers: this.heads,
     let rsp= await this.http.get(ENV.API_GET_ADMIN_TICKET, {
      responseType: "json"
    })
      .toPromise()
      .then(response => JSON.parse(JSON.stringify(response)) as any)
      .catch(this._handleError);
      this._adminTicket = rsp.ticket;
      console.log("logged in ",this._adminTicket);
      return rsp.ticket;
  }
  private _handleError(err: HttpErrorResponse | any) {
    const errorMsg = err.message || 'Error: Unable to complete request.';
    if (err.message && err.message.indexOf('No JWT present') > -1) {
    }

    return errorMsg;
  }
  

  private parseXMLResponse(r: any) {
    let res;
    res=r.split("<ticket>").join("xxxxx").split("</ticket>").join("xxxxx").split("xxxxx")[1]
    return res;
  }

  public async getDocConfig(type:any, ticket:string):Promise<any> {
      console.log("ticket ", this._adminTicket.length)
      if (this._adminTicket==undefined || this._adminTicket.length==0) {
        let resp = await this._getAdminTicket();
        this._adminTicket = resp;
      }
    let rsp = await this.http.get(this.alf_url+ENV.API_GET_DOC_CONFIG + type+'&alf_ticket='+this._adminTicket,
    {
     // headers: this.heads,
     // withCredentials: true,
         responseType: "json"
    })
      .toPromise()
      .then(response => JSON.parse( JSON.stringify(response))  )
      .catch(this._handleError);
      //let rez= ENV.isEncrypted ? atob(this.uService.decodeString(rsp)) : rsp;
      return JSON.parse(JSON.stringify(rsp)); 
  }
  private async getUIConfig():Promise<any> {
    if (this._adminTicket==undefined || this._adminTicket.length==0) {
      let resp = await this._getAdminTicket();
      console.log("ressss  ",resp)
      this._adminTicket = resp;
    }
    let rsp = await this.http.get(this.alf_url+ENV.API_GET_UICONFIG+'?alf_ticket='+this._adminTicket,
    {
      responseType: 'json'
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(this._handleError);

    return JSON.parse(JSON.stringify(rsp));
  }
  private async getNomenclatures():Promise<any> {
    if (this._adminTicket==undefined || this._adminTicket.length==0) {
      let resp = await this._getAdminTicket();
      console.log("ressss  ",resp)
      this._adminTicket = resp;
    }
    let rsp = await this.http.get(this.alf_url+ENV.API_GET_NOMENCLATURES+'?alf_ticket='+this._adminTicket,
    {
      responseType: 'json'
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(this._handleError);

    return JSON.parse(JSON.stringify(rsp));
  }
  public async getDocumentTypes():Promise<any> {
    if (this._adminTicket==undefined || this._adminTicket.length==0) {
      let resp = await this._getAdminTicket();
      this._adminTicket = resp;
    }
    let rsp = await this.http.get(this.alf_url+ENV.API_GET_DOC_TYPES+'?alf_ticket='+this._adminTicket,
    {
      responseType: 'json'
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)))
    .catch(this._handleError);

    return JSON.parse(JSON.stringify(rsp));
  }

  public async getDocTemplate(type:any, token:any):Promise<any> {

  let rsp = await this.http.get(ENV.API_GET_DOCUMENT_TEMPLATE + type,
  {
       responseType: "json",
        headers:
        new HttpHeaders()
    .set('Access-Control-Allow-Headers', window.location.origin)
    .set('Access-Control-Allow-Origin', '*')
    .set('authorization', token)
      
  })
    .toPromise()
    .then(response => JSON.parse( JSON.stringify(response))  )
    .catch(this._handleError);
    //let rez= ENV.isEncrypted ? atob(this.uService.decodeString(rsp)) : rsp;
    return JSON.parse(JSON.stringify(rsp)); 
}

public async getFileContent(nodeRef): Promise<any>  {
  console.log(this.alf_url+ENV.API_GET_FILE_CONTENT + nodeRef+'&alf_ticket='+this._adminTicket)
  let rsp = await this.http.get(this.alf_url+ENV.API_GET_FILE_CONTENT + nodeRef+'&alf_ticket='+this._adminTicket,
  {
       responseType: "text"
  })
    .toPromise()
    .then(response => response)  
    .catch(this._handleError);
    //let rez= ENV.isEncrypted ? atob(this.uService.decodeString(rsp)) : rsp;
    return rsp;  
}

public async getEditorContent(nodeRef,token): Promise<any>  {
  console.log(this.alf_url+ENV.API_GET_FILE_CONTENT + nodeRef+'&alf_ticket='+token)
  let rsp = await this.http.get(this.alf_url+ENV.API_GET_FILE_CONTENT + nodeRef+'&alf_ticket='+this._adminTicket,
  {
   
 
       responseType: "text"
  })
    .toPromise()
    .then(response => JSON.parse( JSON.stringify(response))  )
    .catch(this._handleError);
    //let rez= ENV.isEncrypted ? atob(this.uService.decodeString(rsp)) : rsp;
    return JSON.parse(rsp);  
}
public async concatenateFiles(data:any, token):Promise<any> {
  return this.http.post(ENV.API_POST_CONCATENATEFILES,data,
        {
          headers:
          new HttpHeaders()
      .set('Access-Control-Allow-Headers', window.location.origin)
      .set('Access-Control-Allow-Origin', '*')
      .set('authorization', token)
        
    }
  )
  .toPromise().then(response => JSON.parse(JSON.stringify(response)) as Object)
  .catch(this._handleError);
}
  public async getPDFRendition(noderef): Promise<any> {
    let rsp = await this.http.get(this.alf_url+ENV.API_GET_PDF_RENDITION+noderef+"&alf_ticket="+this._adminTicket)
    .toPromise()
    .then(response => response as any)
    .catch(this._handleError);
    return rsp;
  }
  public async getUserFolder(folder:any):Promise<any> {
    if (this._adminTicket==undefined || this._adminTicket.length==0) {
      let resp = await this._getAdminTicket();
      this._adminTicket = resp;
    }
    console.log("checking user folder  ",this.alf_url+ENV.API_GET_USER_FOLDER+folder+"&alf_ticket="+this._adminTicket)
    return this.http.get(this.alf_url+ENV.API_GET_USER_FOLDER+folder+"&alf_ticket="+this._adminTicket)
    .toPromise().then(response => JSON.parse(JSON.stringify(response)) as Object)
    .catch(this._handleError);
  }

  public async quickGenerator(token, data:any):Promise<any> {
    let rsp2 = await this.dataService.quickGenerator(token, data.payload);
    return JSON.parse(JSON.stringify(rsp2)); 
    return this.http.post(this.alf_url+ENV.API_POST_QUICKGENERATOR+"?alf_ticket="+this._adminTicket,data,
    {
      
    })
    .toPromise().then(response => JSON.parse(JSON.stringify(response)) as Object)
    .catch(this._handleError);
  }
  public async quickGenerator2(data:any):Promise<any> {
    return this.http.post(this.alf_url+ENV.API_POST_QUICKGENERATOR+"?alf_ticket="+this._adminTicket,data,
    {
      
    })
    .toPromise().then(response => JSON.parse(JSON.stringify(response)) as Object)
    .catch(this._handleError);
  }

  public async openHtml(nodeRef,token): Promise<any>  {
    //let rs = await this._validateToken(this.user.ticket);
  console.log(nodeRef)
     
   
      window.open(this.alf_url+ENV.API_GET_CONTENT + nodeRef+'?alf_ticket='+token)
       
  }

  public async getAdvSearch(data:any, site:string, token: string):Promise<any> {

    let rsp = await this.http.get(this.alf_url+ENV.API_GET_ADVANCED_SEARCH+"?searchstr="+data+"&site="+site+"&alf_ticket="+token, {
      responseType: 'text'
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)) as any)
    .catch(this._handleError);
   // let rez= ENV.isEncrypted ? atob(this.uService.decodeString(rsp)) : rsp;
    return JSON.parse(rsp); 
  } 

  public async downloadFile(nodeRef): Promise<any>  {
    //let rs = await this._validateToken(this.user.ticket);
  console.log(nodeRef)
     
 
      window.open(this.alf_url+ENV.API_DOWNLOAD_FILE + nodeRef+'/content/?a=true&alf_ticket='+this._adminTicket,"_self")
       
  }
  public async copyNode(noderef,data): Promise<any>  {
    //let rs = await this._validateToken(this.user.ticket);
  console.log(data)
     
 console.log(this.alf_url+ENV.API_POST_COPY_NODE +noderef+"/copy?alf_ticket=" + this._adminTicket)
   
      return this.http.post(this.alf_url+ENV.API_POST_COPY_NODE +noderef+"/copy?alf_ticket=" + this._adminTicket, data,
      {
        //headers: this.heads,
        //withCredentials: true
      })
      .toPromise()
      .then()
      .catch(this._handleError)
 
  }
       
  public async clientPortalDashboard(token, folder): Promise<any>  {
    return await this.dataService.browseSiteFolders(token, folder);
   let str=this.alf_url+ ENV.API_BROWSE_SITE_FOLDER+folder;
 
   console.log("str is ", str)
   let rsp = await this.http.get(str, {
     responseType: 'text',
     headers:
          new HttpHeaders()
          .set('Access-Control-Allow-Headers', window.location.origin)
          .set('Access-Control-Allow-Origin', '*')
          .set('authorization', token)
   })
  .toPromise()
  .then(response => response)
  .catch(this._handleError);
  try {
   return JSON.parse(rsp); 
   } catch(e) {
     return e;
   }

}
  public async viewFile(id, token):Promise<any> {
    //let str=this.alf_url+ENV.API_GET_FILE_CONTENT+id+"&alf_ticket="+this._adminTicket;
    let str=ENV.API_GET_VIEW_FILE+"?noderef="+id
    let rsp = await this.http.get(str, {
      responseType: 'text',
      headers:
      new HttpHeaders()
  .set('Access-Control-Allow-Headers', window.location.origin)
  .set('Access-Control-Allow-Origin', '*')
  .set('authorization', token)
    })
   .toPromise()
   .then(response => response)
   .catch(this._handleError);
   return rsp;
  };

  public async viewImage(id):Promise<any> {
    let url = this.alf_url + ENV.API_GET_IMAGE_CONTENT + id + '&alf_ticket=' + this._adminTicket;
    let response = await this.http.get(url, {
      
    })
      .toPromise()
      .catch(this._handleError);
    return response;
  };

  
  public async browseReportFolders(token, orgIds):Promise<any> {
    let data={ site: "dosi2", folders: orgIds}
    
    let rsp2 = await this.dataService.browseReportFolders(token,{ site: "dosi2", folders: orgIds});
    return JSON.parse(JSON.stringify(rsp2)); 
    let rsp = await this.http.post( this.alf_url+ENV.API_BROWSE_REPORT_FOLDRES+"?alf_ticket=" + this._adminTicket, {"data":data},
    
    {
      headers: {} //this.heads
    })
    .toPromise()
    .then()
    .catch(this._handleError)
  
    return JSON.parse(JSON.stringify(rsp2)); 
  }


  public async browseFolder(noderef:string, token:string):Promise<any> {
 
    let rsp=await this.http.get(this.alf_url+ENV.API_GET_BROWSEFOLDER+noderef+"&alf_ticket="+token,{
      responseType: 'text'
    })
    .toPromise()
    .then(response => JSON.parse(JSON.stringify(response)) as any)
    .catch(this._handleError);
    return JSON.parse(rsp); 
  }
  public async updateNodeFile(data: any): Promise<any> {
 
      let rsp = await this.http.post( this.alf_url+ENV.API_UPDATE_NODE+"?alf_ticket=" + this._adminTicket, data, {
        responseType: 'text'
      })
      .toPromise()
      .then()
      .catch(this._handleError)
    
      return JSON.parse(rsp); 
    
    
  }
  public async createUpdateFileInSiteFolder(data ): Promise<any> {
    console.log(data)
    return this.http.post(this.alf_url+ENV.API_POST_CREATE_UPDATE_FILE_IN_SITE_FOLDER +"?alf_ticket=" + this._adminTicket, data,
      {
        //headers: this.heads,
        //withCredentials: true
      })
      .toPromise()
      .then()
      .catch(this._handleError)
  }

  
  public async deleteNode(noderef: any): Promise<any> {

    let rsp = await this.http.delete(this.alf_url+ENV.API_POST_DELETE_NODE+noderef+"?alf_ticket=" +this._adminTicket,  {
      responseType: 'text'
    })
      .toPromise()
      .then()
      .catch(this._handleError);
     // let rez= ENV.isEncrypted ? atob(this.uService.decodeString(rsp)) : rsp;
      return JSON.parse(rsp); 
  } 
 
}
