import { AfterViewInit, ChangeDetectorRef, Component, Inject, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { NbAuthService, NbLoginComponent, NB_AUTH_OPTIONS, NbResetPasswordComponent } from '@nebular/auth';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { LanguageService } from '../../../@core/services/language/language.service';
import * as environment from '../../../../environments/environment.prod';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { NbComponentStatus, NbGlobalPhysicalPosition, NbGlobalPosition, NbStepperComponent, NbToastrService } from '@nebular/theme';
import { ToastService } from '../../../@core/services/toast/toast.service';

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
})
export class NgxLoginComponent extends NbLoginComponent implements AfterViewInit {
public appVersion:any=environment.environment.version
public mfa_on:any=false;
private is_public:boolean=true;
public loaded:Boolean=false;
public mfa_step:number=0;
isDisabled=true; 
protected position: NbGlobalPosition = NbGlobalPhysicalPosition.TOP_RIGHT;
@ViewChild('loginStepper') stepperComponent: NbStepperComponent;

  constructor(private fb: FormBuilder, 
   public auth:AuthService, private utils:UtilsService,
     private toastrService:NbToastrService,
    service: NbAuthService, 
    @Inject(NB_AUTH_OPTIONS) options:{},
     cd: ChangeDetectorRef, router: Router,public ls:LanguageService){
    super(service, options, cd, router);
  }

  ngAfterViewInit(): void {
    this.initializeConfig();
  }
  authResult:object={
    status:'',
  
  };  

async initializeConfig() {
  this.mfa_on=await this.utils.getMFAConfig();
  if (this.mfa_on) { 
    try {
    this.is_public = this.utils.app_config.is_public_portal;
    } catch(e) { this.is_public=false; }
  }
  
  if (this.is_public==false ) this.mfa_on=false;
  console.log("this MFA config is ", this.mfa_on, this.is_public)
  this.loaded=true;
}  
async authentification(){
await this.auth.performLogin(this.user);
console.log("user logged is ", this.user)
}
async mfAuthentication() {
  console.log("waiting on response from server to send stepper to next stept")
  let obj={ username: this.user.username, password: this.user.password }
  this.user.mfacode=null;
  let resp=await this.auth.performMFALogin(obj);
  console.log("mfa auth response is ", resp)
  if (resp.status==200) {
  this.stepperComponent.next();
  } else {
    //this.errors=["asdfasdfasdf"];
    this.showToast("danger", this.ls.strings.incorrect_credentials,'');
  }
}
async performMFAuthentication() {
  console.log(this.user)
  let obj={ username: this.user.username, mfacode: this.user.mfacode }
  await this.auth.performLogin(obj);
};

onLinkClick(event: Event) {
if(this.isDisabled) {
  event.preventDefault();
}
}
public showToast(type: NbComponentStatus, title: string, body: string,buttonText?: string, buttonCallback?:()=>void) {
  const config = {
    status: type,
    destroyByClick: true,
    duration: 3000,
    hasIcon: true,
    position: this.position,
    preventDuplicates: true,
  };
  const titleContent = title ? ` ${title}` : "";

  let toast=this.toastrService.show(body, `${titleContent}`, config);
  console.log(title);
  if (buttonText && buttonCallback) {
 /*      toast.buttons = [
        {
          text: buttonText,
          role: 'cancel',
          handler: buttonCallback
        }
      ]; */
    }
}


}
