import { Component, OnDestroy, OnInit,AfterViewInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuItem, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { LanguageService } from '../../../@core/services/language/language.service';
import { AuthService } from '../../../@core/services/auth/auth.service';
import { DataService } from '../../../@core/services/data/data.service';
import { SearchComponent } from '../../../pages/admin/search/search.component';
import { UtilsService } from '../../../@core/services/utils/utils.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy,AfterViewInit {
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  newNotifications:number=0;
  isSystemUser:boolean=false;
  adminLogged: boolean =false;
  themes = [
 
    {
      value: 'corporate',
      name: 'Corporate',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
   
   
  ];

  currentTheme = 'corporate';

  @ViewChild('organizationsSelect') organizationsSelect;
  @ViewChild('suborganizationSelect') suborganizationSelect;
  parentOrganizations=[];
  suborganizations =[];
  denumire=[];

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private userService: UserData,
              private layoutService: LayoutService,
              private breakpointService: NbMediaBreakpointsService,
              public data: DataService,
              private changeDetector: ChangeDetectorRef,
              public ls:LanguageService,public auth:AuthService,
              private cdRef: ChangeDetectorRef, private utils: UtilsService,
              private router:Router,
              private route:ActivatedRoute,
            ) {
          
              
  }

  ngOnInit() {
    this.isSystemUser=this.auth.isSystemRole();
    this.currentTheme = 'corporate';
    if (this.auth.user.roles==undefined) return;
    let checkRoleExistence = roleParam => this.auth.user.roles.some( role => role == roleParam)

    // if(checkRoleExistence("superadmin") || checkRoleExistence("admin")){
    //   this.adminLogged = true;
    //    this.getParentOrganizations();
    // }
    if(this.isSystemUser==true) {
      this.adminLogged = true;
      this.getParentOrganizations();
    }
    else {
      //console.log("ELSE")
      setTimeout(() => {
        this.getParentOrganizations();
      }, 1000);
     
      this.getUserDetails()
    }

   

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);
      //console.log

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
      this.menuService.onItemClick().subscribe((event) => {
        if (event.item.title === this.ls.strings.logout) {
         this.auth.logout()
        
        }
      });
  }

  async getParentOrganizations(){
    this.parentOrganizations = [];
   //let r = await this.data.getParentOrganizations(this.auth.user.token);
   let r = await this.utils.getOrganizationNames();
    r = r.filter(el=>el.parent == '');
   // console.log("RRRRR is ", r);

    if(r.length>0){
      console.log('ORGANIZATIONS TO DISPLAY IN HEADERS SELECT', r.length)
      this.parentOrganizations = r;
      //console.log("parent orgs are ", this.parentOrganizations);
      //console.log("preff org is ", this.auth.user.preffered_organization)
      if(this.auth.user.preffered_organization==undefined) this.auth.user.preffered_organization = r[0]._id;
      if(this.organizationsSelect!==undefined) {
        this.organizationsSelect.selected = this.auth.user.preffered_organization; 
        console.log("MMMMMMS",this.organizationsSelect)
      }
    } 
  
   
    else {
     // console.log("xxx---------------------")
      this.sidebarService.collapse('menu-sidebar');
      this.layoutService.changeLayoutSize();
    }
  };

 
  async selectionChange(event){ 
    //console.log("EVENt is ", event)
     this.auth.user.preffered_organization = event;
     this.auth.user.selected_suborganization = null;
    //console.log('---------USER------', this.auth.user)

    await this.auth._updateUserFromLocalStorage()
    //this.cdRef.detectChanges();
    //this.refresh();
    //console.log('---------USER2------', this.auth.user)
    this.data.setObjectData(event)
  };

  refresh() {
    location.reload()
  }
  

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
    
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
 ngAfterViewInit(){
   //console.log(this.ls.strings.logout)
   
 }
 async getNotificationsCount() {
  let r=await this.data.countCollection({ coll_name: "notification", query: { recipientId: this.user._id, new: true}}, this.auth.user.token) 
  if (r.status==200) {
    this.newNotifications=r.number;
  }
  //console.log("notificationscount  ", r);
 };

 async getUserDetails(){
  let r = await this.data.getUserDetails(this.auth.user.token, this.auth.user.username, true, true);
  this.user = r;
  if (this.auth.user._id==null || String(this.auth.user._id)=="undefined" || this.auth.user._id=="") { this.auth.user._id=r._id;}
  // console.log('USER on view dashboard', this.user);
  this.suborganizations=[];
  for (var k in this.user.organizations) {
    this.suborganizations.push(this.user.organizations[k]);
    //console.log("User Suborg are ", this.suborganizations);
  };
   this.getSuborgNameById()
   if (this.auth.user) this.getNotificationsCount();
};

async getSuborganizations(){
  let resp = await this.data.getUserSuborganizations(this.auth.user.preffered_organization, this.auth.user.token, true);
  //console.log('SUBORG', resp)
  if(resp.status!=404 && resp.length>0){
    this.suborganizations=resp.filter(el=>this.user.organizations.indexOf(el._id)!=-1)
    if (this.suborganizations.length>0) {
    this.suborganizationSelect.selected=this.suborganizations[0]._id;
    this.auth.user.selected_suborganization=this.suborganizations[0]._id;
    }
   
  } 
};

async getSuborgNameById() {
  let resp = await this.data.getSuborgNameById(this.auth.user.token, this.user, true);
  //console.log("resp is ", resp)
  this.denumire =[];
  for (var k in resp.organizations.docs){
    this.denumire.push(resp.organizations.docs[k].denumire)
  };
  //console.log("after resp is , denumiri = ", this.denumire)
  //this.organizationSelect.selected=this.denumire[0]
  //console.log( "RESP is ", this.denumire)
};

searchButton() {
  this.router.navigateByUrl('pages/admin/admin-search');
}

}
